import React, { useEffect, useState } from 'react';
import { PreOrder } from '../components/PreOrder';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import useWindowSize from '../utils';

const Timer = ({ endTime, trans }) => {
  const calculateTimeLeft = () => {
    let difference = new Date(endTime).getTime() - new Date().getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="row clearfix justify-content-center">
        <div className="column counter-column col-md-2 col-6">
          <div className="count-outer count-box clearfix justify-content-center row">
            <span className="count-text">{timeLeft.days}</span>
            <h4 className="counter-title">{trans.day}</h4>
          </div>
        </div>

        <div className="column counter-column col-md-2 col-6">
          <div className="count-outer count-box clearfix justify-content-center row">
            <span className="count-text">{timeLeft.hours}</span>
            <h4 className="counter-title">{trans.hours}</h4>
          </div>
        </div>

        <div className="column counter-column col-md-2 col-6">
          <div className="count-outer count-box clearfix justify-content-center row">
            <span className="count-text">{timeLeft.minutes}</span>
            <h4 className="counter-title">{trans.minutes}</h4>
          </div>
        </div>

        <div className="column counter-column col-md-2 col-6">
          <div className="count-outer count-box clearfix justify-content-center row">
            <span className="count-text">{timeLeft.seconds}</span>
            <h4 className="counter-title">{trans.seconds}</h4>
          </div>
        </div>
      </div>
    </>
  );
};
const PrincipalBanner = () => {
  const size = useWindowSize();
  const [videoModal, setVideoModal] = useState(false);

  const handlerVideo = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      width: size.width * 1,
      html: (
        <iframe
          width={size.width * 1}
          height={size.width < 400 ? size.height * 1 : size.height * 0.5}
          src="https://www.youtube.com/embed/a1eE-yUTy3Y"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ),
      didOpen: () => {
        // `MySwal` is a subclass of `Swal`
        //   with all the same instance & static methods
      },
    });
  };

  return (
    <>
      <style>{`.swal2-popup{background: none !important}`}</style>
      <section
        style={{
          height: size.height + 'px',
        }}
        className="container-section-two full-view-container"
      >
        <video className="video-main-train" autoPlay muted loop id="videoTrain">
          <source
            src={require('../public/videos/video_banner-section.mp4')}
            type="video/mp4"
          />
        </video>

        <div className="banner-section-two">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 offset-lg-5 col-md-12 col-sm-12">
                <div
                  className="inner-column wow slideInLeft animated"
                  style={{
                    marginTop: '-20%',
                    visibility: 'visible',
                    animationName: 'slideInLeft',
                  }}
                >
                  <h2 className="text-light text-right">
                    <mark className="text-bg-fade box-purple">
                      ¡Están atacando tu cuerpo!
                      <br /> Ingresa y defiéndelo del Dr. Neuro
                    </mark>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {true && (
                <div className="btn-box">
                  <button
                    onClick={handlerVideo}
                    className="theme-btn btn-style-four"
                    enabled={'true'}
                  >
                    <h4 className="text-light text-right">
                      ¡Cuidado, spoilers!
                    </h4>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const PrincipalBannerMobile = () => {
  const size = useWindowSize();

  const handlerVideo = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      width: size.width * 1,
      html: (
        <iframe
          width="100%"
          height={size.width < 400 ? size.height * 1 : size.height * 0.5}
          src="https://www.youtube.com/embed/a1eE-yUTy3Y"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ),
    });
  };

  return (
    <>
      <style>{`.swal2-popup{background: none !important}`}</style>
      <section className="container-section-two mb-5">
        <video className="video-main-train" autoPlay muted loop id="videoTrain">
          <source
            src={require('../public/videos/video_banner-section.mp4')}
            type="video/mp4"
          />
        </video>

        <div className="banner-section-two">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 offset-lg-5 col-md-12 col-sm-12">
                <div
                  className="inner-column wow slideInLeft animated"
                  style={{
                    marginTop: '-20%',
                    visibility: 'visible',
                    animationName: 'slideInLeft',
                  }}
                >
                  <h2 className="text-light text-right">
                    <mark className="text-bg-fade box-purple">
                      ¡Están atacando tu cuerpo!
                      <br /> Ingresa y defiéndelo del Dr. Neuro
                    </mark>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {true && (
                <div className="btn-box">
                  <button
                    onClick={handlerVideo}
                    className="theme-btn btn-style-four"
                    enabled={'true'}
                  >
                    <h4 className="text-light text-right">
                      ¡Cuidado, spoilers!
                    </h4>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export const Home = () => {
  const size = useWindowSize();
  return (
    <>
      <PrincipalBannerMobile />
      <section
        style={{
          borderTopLeftRadius: '15%',
          borderTopRightRadius: '15%',
          borderBottomLeftRadius: '5%',
          borderBottomRightRadius: '5%',
          boxShadow: '1px 23px 45px 7px #B7B7B7',
        }}
        className="default-section bg-primary "
      >
     {/*   <section className="counter-section">
          <div className="auto-container ">
            <div className="sec-title centered">
              <h2>
                <mark className="text-bg-fade box-orange">Lanzamiento en</mark>
              </h2>
            </div>

            <div className="inner-container">
              <div className="inner-column wow fadeInDown">
                <div className="inner-column wow fadeInDown">
                  <div id="timer" className="inner-column">
                    <div className="fact-counter ">
                      <Timer
                        endTime="Feb 10, 2024 15:00:00"
                        trans={{
                          day: 'Dias',
                          hours: 'Horas',
                          minutes: 'Minutos',
                          seconds: 'Segundos',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
*/}
        <section className="platform-section">
          <div className="auto-container">
            <div className="title-box mb-2">
              <h2 className="text-light">
                Somos el primer videojuego de Latinoamérica en promover hábitos
                saludables en niños y jóvenes con endocrinólogos pediatras.
              </h2>
              <br></br>
              {/*             <br></br>
              <h5 className="text-light">
                Con la tecnología como nuestra aliada, promovemos la
                alimentación saludable, la higiene del sueño y la actividad física
                mediante el envío de retos diarios.
              </h5>*/}
            </div>
            <div className="text-center pb-5">
              <img
                width={size.width > 500 ? '80%' : '90%'}
                src={require('../public/images/portada_endoforce.jpg')}
                alt=""
              />
            </div>

            <div className="button-box text-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.LinkCode.EndoForce&hl=es_BO"
                className="theme-btn btn-style-one text-light"
              >
                <img
                  width={size.width > 400 ? '70%' : '70%'}
                  src={require('../public/images/playstore.png')}
                  alt=""
                />
              </a>
            </div>
            <br></br>
            <div className="button-box text-center">
              <a
                href="https://apps.apple.com/co/app/endoforce/id6480163085?l=en-GB"
                className="theme-btn btn-style-one text-light"
              >
                <img
                  width={size.width > 400 ? '60%' : '70%'}
                  src={require('../public/images/appstore.png')}
                  alt=""
                />
              </a>
            </div>
          </div>
          <br></br>
          <div className="auto-container">
            <div className="title-box mb-2">
              <h5 className="text-light">
                Con la tecnología como nuestra aliada, promovemos la
                alimentación saludable, la higiene del sueño y la actividad física
                mediante el envío de retos diarios.
              </h5>
            </div>
          </div>
        </section>
      </section>
      <PreOrder />
    </>
  );
};
